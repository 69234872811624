'use client'
import { emptyCart } from '@/app/(store)/actions/cart/cart.actions'

type Props = {
  children: React.ReactNode
  lineIds: string[]
  className?: string
}
const EmptyCartButton = ({ children, lineIds, className }: Props) => {
  const submitAction = async () => {
    await emptyCart(null, lineIds)
  }
  return (
    <form action={submitAction}>
      <button type="submit" className={className}>
        {children}
      </button>
    </form>
  )
}

export default EmptyCartButton
